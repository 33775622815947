<template>
    <div>
      <Mould :data="data"></Mould>
    </div>
</template>

<script>
import { Mould } from '@components'
export default {
  name: 'Dispatcher',
  components: {
    Mould
  },
  data () {
    return {
      data: {
        title: '急救云调度系统',
        introduce: '云调度系统用户通过话务系统快速与患者联系，了解伤情，结合地图确认患者呼叫定位，并按照“就近、救急、就专科救治能力、就患者或家属意愿”的急救原则，反馈医疗急救信息，下达医疗急救任务，指派急救车与医务人员出车救治，或远程指导相应的急救措施。地图实时查看出车情况，针对实际状况快速调整，增改急救车辆，实现救护车的统一调度，使全程急救信息化、指挥调度网络化，统一管理和使用医院医疗急救公共信息资源，快速为患者提供院前急救服务。',
        list: [
          {
            title: '产品展示',
            content: '',
            produceImage: require('@images/produce/ic_common_sidebar_joinus.png')
          },
          {
            title: '核心优势',
            content: '高度网络化、高度智能化、充分整合各种资源实现高效调度。'
          },
          {
            title: '功能介绍',
            content: '云调度系统实现：受理120呼入电话；快速询问和记录患者伤情并生成急救预案；调度分配医务人员、驾驶员、救护车辆；推送出车指令至相关人员及车辆移动终端；实时监控急救任务进展情况；急救数据云端共享；对实际急救现场情况进行增援、改派以及重大灾害、突发事件处理应对。'
          },
          {
            title: '使用角色'
          }
        ]
      }
    }
  },
  created () {
    console.log(1111)
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
